<template>
  <!-- https://vuetifyjs.com/en/api/v-app-bar/ -->
  <v-app-bar app color="primary" dark src="@/assets/ceu.jpg"  v-resize="onResize">
    <v-img alt="Logo" class="shrink ml-1 mr-5" contain src="@/assets/logo.png" transition="scale-transition" width="40"/>
    <span class="title" @click="navigate('/')">Filosofia & Poesia</span>
    <template v-slot:img="{ props }">
      <v-img gradient="to top right, rgba(255,255,255,.03), rgba(255,255,255,.1)" v-bind="props" @click="navigate('/')"></v-img>
    </template>
    <v-spacer></v-spacer>
    <span class="welcome mr-4" v-if="user && user.name && screenWidth > 500" @click="navigate('/profile')">{{ `${$t('Hello')}, ${user.name.split(' ')[0]}` }}!</span>
    <Button class="navbar-button" :color="'primary'" :width="50" outlined @click="menuActivated = !menuActivated">
      <v-icon>mdi-menu</v-icon>
    </Button>
    <v-slide-x-reverse-transition>
      <v-card :class="[`menu ${color}-gradient shadow`]" v-if="menuActivated" elevation="2">
        <v-list-item v-if="user && screenWidth <= 500">
          <v-list-item-title class="welcome">Olá, {{ user.name.split(' ')[0] }}!</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$route.path !== '/'" @click="navigate('/')">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user" @click="navigate('/profile')">
          <v-list-item-title>{{ $t('My Profile') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user" @click="navigate('/liked')">
          <v-list-item-title>{{ $t('Liked Posts') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!user" @click="loginActivated = true">
          <v-list-item-title>{{ $t('Sign In') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!user" @click="signUpActivated = true">
          <v-list-item-title>{{ $t('Sign Up') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigate('/moment-post')">
          <v-list-item-title>{{ $t('Post for The Moment') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigate('/collaborate')">
          <v-list-item-title>{{ $t('Collaborate') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user" @click="navigate('/settings')">
          <v-list-item-title>{{ $t('Config') /* Dunno why the word 'Settings' dont get translated */ }}</v-list-item-title>
        </v-list-item>
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title class="idiom d-flex align-center">
              {{ $t('Idiom') }} <img class="flag ml-auto mr-2" :src="flags[$store.getters['app/app'].idiom]" />
            </v-list-item-title>
          </template>
          <v-list-item class="ml-4 mr-3" @click="$store.dispatch('app/changeIdiom', 'pt')">
            Português <img class="flag ml-auto" :src="flags.pt" />
          </v-list-item>
          <v-list-item class="ml-4 mr-3" @click="$store.dispatch('app/changeIdiom', 'en')">
            English <img class="flag ml-auto" :src="flags.en" />
          </v-list-item>
          </v-list-group>
        <v-list-item v-if="user" @click="signOut">
          <v-list-item-title>{{ $t('Sign Out') }}</v-list-item-title>
        </v-list-item>
      </v-card>
    </v-slide-x-reverse-transition>
    <Login  v-if="loginActivated"  :title="'Entrar'"
      @close="loginActivated = false" />
    <SignUp v-if="signUpActivated" :title="'Cadastrar'" 
      @close="signUpActivated = false" />
  </v-app-bar>
</template>
<script>
import { showSnackBar } from '@/services/snackbar'

export default {
  name: 'Navbar',
  components: {
    Button:   () => import('@/components/ui/Button'),
    Login:    () => import('@/components/Login'),
    SignUp:   () => import('@/components/SignUp')
  },
  props: {
    user: Object,
    color: String
  },
  data: () => ({
    screenWidth: window.innerWidth,
    menuActivated:   false,
    loginActivated:  false,
    signUpActivated: false,
    flags: {
      en: require('@/assets/usa-flag.png'),
      pt: require('@/assets/brazil-flag.png')
    },
  }),
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth;
    },
    async signOut () {
      await this.$store.dispatch('auth/signOut')
    
      if (this.$route.path !== '/')
        this.$router.push('/')
    },
    verifyIfIsAllowedToNavigateToRoute (route) {
      let allowed = false

      switch (route) {
        case 'liked': 
          if (!this.user?.likedPosts?.length) {
            showSnackBar({
              message: `You didn't liked some post yet.`,
              config: {
                color: 'orange',
                timeout: 3500
              }
            })
            break
          }
          allowed = true
          break 
        default: 
          return true
      } 
      return allowed
    },
    navigate (route) {
      if (!this.verifyIfIsAllowedToNavigateToRoute(route.slice(1)))
        return
      
      if (route === this.$route.path)
        return

      this.$router.push(route)
      
      this.menuActivated = false
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_classes.scss';
  
  .v-app-bar { 
    .v-image, span { cursor: pointer; }

    span { 
      text-shadow: 0px 0px 4px black;
      font-size: 35px;
      font-family: 'Pacifico', cursive !important;
    }

    .v-toolbar__content {
      span.welcome { font-size: 1em !important; }
    }

    .navbar-button { background: white; }
    
    .menu {
      position: fixed !important;
      width: 190px !important;
      max-height: calc(100vh - 75px);
      top: 60px !important;
      right: 15px !important;
      padding: 10px 0 15px;
      text-align: left;
      text-transform: none;
      overflow-y: scroll;

      @media (min-height: 555px) {
        overflow-y: hidden;
      }
      
      .v-list-item__title, .v-list-item {
        font-family: 'Nunito', sans-serif !important;
        color: white;
      }

      .v-list-item__title, .v-list-item {
        &:hover:not(.menu .welcome) { color: gold !important; }
      }

      ::v-deep {
        .v-list-item__icon { 
          min-width: 0 !important;
          margin: 0 !important;
        }
      }

      .flag { 
        width: 33px;
        height: 30px;
      }
    }
  }

</style>
